import React from "react"
import {graphql} from "gatsby"
import MainLayout from "Layout/layout"
import IntroText from "Layout/IntroText/IntroText"
import RelatedProjects from "Layout/Projects/RelatedProjects/RelatedProjects"

const Studio = ({
  data,
  location
}) => {

  const {page, projects, optionsPage} = data
  const gI = optionsPage.edges[0].node.global_data.generic_images

  const studioProjects = projects.edges.filter(
    project => project.node.categories_names.filter(
      item => item.taxonomy === "research_track"
    ).length > 0 && project.node.categories_names.filter(
      item => item.taxonomy === "research_track"
    )[0].name.toLowerCase() === page.title.toLowerCase()
  )

  return (
    <MainLayout location={location}>
      <IntroText intro={page.acf.intro_text} headline={page.title} genericImages={gI} classes="py-5" />
      <RelatedProjects projects={studioProjects} headline="Studio for Germinating Technologies" />
    </MainLayout>
  )
}

export const query = graphql`
  query studioPageQuery {
    page: wordpressPage(wordpress_id: {eq: 1006}) {
      title
      slug
      acf {
        intro_text {
          paragraphs {
            paragraph
          }
        }
      }
    }
    projects: allWordpressWpProjects {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 684) {
                  src
                  width
                  height
                  originalName
                }
              }
            }
          }
          categories_names {
            name
            taxonomy
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          global_data {
            generic_images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Studio
